// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// Katex
import "katex/dist/katex.min.css"

// This allows the shell prompt biz
require("prismjs/plugins/command-line/prism-command-line.css")